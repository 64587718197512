import { useEffect } from 'react';
import './App.css';
import { Footer, Header } from './layouts';
import routes from './routes/routes';
import AOS from "aos";
import "aos/dist/aos.css";

function App() {

  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);

  return (
    <div className="App flex flex-col min-h-[100vh]">

      <Header />

      <main className='flex-1' style={{ alignContent: 'center' }}>{routes()}</main>

      <Footer />

    </div>
  );
}

export default App;
