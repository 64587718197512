import { faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <footer className='p-3 sm:p-10 text-white bg-[#583835]'>

            <div className='container grid grid-cols-12 p-3 lg:gap-5'>

                <div className='col-span-12 sm:col-span-8 lg:col-span-4 flex items-center gap-3'>

                    <img src={require('../../assests/images/eu.png')} alt='' className='w-fit max-w-[45%]' />
                    <img src={require('../../assests/images/ceuta-open-future-el-angulo-positivo.png')} alt='' className='w-fit max-w-[55%]' />

                </div>


                <div className='col-span-12 lg:col-span-6 flex flex-col lg:flex-row items-center justify-between text-center -order-1 lg:order-[initial] my-10 md:my-0'>


                    <Link to={'/contacta'} className='mb-10 lg:mb-0 capitalize font-medium lg:font-normal'>join to us !</Link>

                    <Link to={'/'} className='mb-10 lg:mb-0 capitalize font-medium lg:font-normal'>Inicio</Link>

                    <Link to={'/planes'} className='mb-10 lg:mb-0 capitalize font-medium lg:font-normal'>Planes</Link>

                    <Link to={'/equipo'} className='mb-10 lg:mb-0 capitalize font-medium lg:font-normal'>Equipo</Link>

                    <Link to={'/contacta'} className='mb-10 lg:mb-0 capitalize font-medium lg:font-normal'>contacto</Link>

                    <Link to={''} className='mb-10 lg:mb-0 capitalize font-medium lg:font-normal'>privacy & conditions </Link>

                </div>

                <div className='col-span-12 sm:col-span-4 lg:col-span-2 mt-5 sm:mt-0 flex items-center justify-center sm:justify-end'>

                    <a href='https://x.com/yebalakoffie?s=11&t=9QWowqhETfjtqRePZyloWw' target='_blank'>

                        <FontAwesomeIcon icon={faTwitter} className='text-white text-[20px] me-4 cursor-pointer' />

                    </a>

                    <a href='https://www.instagram.com/rovlecoffee/' target='_blank'>

                        <FontAwesomeIcon icon={faInstagram} className='text-white text-[20px] me-4 cursor-pointer' />

                    </a>

                    <a href='https://www.linkedin.com/company/yebala-koffie-io/' target='_blank'>

                        <FontAwesomeIcon icon={faLinkedin} className='text-white text-[20px] me-4 cursor-pointer' />

                    </a>

                </div>

                <p className='col-span-12 text-[14px] text-center'>
                    Yebala Koffie @ 2023. All rights reserved to Chaara App
                </p>

            </div>

        </footer>
    )
}
